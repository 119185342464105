import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
export const _frontmatter = {
  "title": "Helium Wireless IoT Network"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page helium-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Helium Wireless IoT Network`}</h1>
        <p><a parentName="p" {...{
            "href": "https://helium.com"
          }}>{`Helium`}</a>{` is the fast growing wireless network in history, with now over 450,000 "hotspots" (or "gateways") in over 35,000 cities globally.
Helium provides the LoRaWAN-standard protocol as used by the Huey H₂O water sensor and others.
Helium  "hotspots" are hosted by regular people and provide coverage for their neighbourhood over several kilometers in radius (with a roof mounted antenna), and are rewarded for
doing so with cryptocurrency. To use the Huey H₂O sensor you
do not need to own a Helium hotspot, but you do need to be within range of one. Most of the US population is within range, and Helium is growing
fast across the world, including Australia.`}</p>
        <h2>{`Helium Network Coverage`}</h2>
        <p>{`Helium network hotspots can be seen at `}<a parentName="p" {...{
            "href": "https://explorer.helium.com/hotspots"
          }}>{`https://explorer.helium.com/hotspots`}</a>{`. Each hotspot should cover at least the hexagon it occupies, probably
the six surrounding it and potentially much more.`}</p>
        <p>{`Helium Mappers (`}<a parentName="p" {...{
            "href": "https://mappers.helium.com"
          }}>{`https://mappers.helium.com`}</a>{`) is a community effort to map actual reception.`}</p>
        <p>{`Mapped hexagons have had reception and are shown in green. Unmapped hexagons that contain a hotspot appear as filled grey hexagons, and they
and their neighbours still likely have reception.`}</p>
        <p>{`While Helium hosts are incentivized to maintain and optimise their coverage, successful reception is subject to many factors (antenna size and
placement, correct frequency, physical obstacles, internet connection  etc).`}</p>
        <p>{`Huey cannot guarantee Helium reception at any location, even if the maps above claim that there is. However, if Helium  coverage is not available where you require it, you can purchase and install your own hotspot to extend the network for you and your neighbours. See this link for information `}<a parentName="p" {...{
            "href": "https://www.helium.com/hosts"
          }}>{`https://www.helium.com/hosts`}</a>{`.`}</p>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      